.navbar .navbar-logo {
    width: 160px;
}

.navbar .navbar-logo .logo {
    display: block;
    background: url("../../media/logo.svg") no-repeat;
    background-size: 160px;
    background-position-y: center;
}

@media (max-width: 419px) {
    .navbar .navbar-logo {
        width: 100px;
    }
    .navbar .navbar-logo .logo {
        background-size: 100px;
    }
}


/* Login Page */

.auth-card {
    font-size: 1rem;
}

.benjamin-logo-container {
    text-align: center;
    margin-bottom: 20px;
}

.auth-card .image-side {
    background: url("../../media/login-bg2.png") right, url("../../media/login-bg.jpg") center;
    background-size: cover;
}


/* List Group item */

.list-group-item {
    &.active {
        background-color: $theme-color-1;
        border-color: $theme-color-1;
    }
}


/* Date picker */

.react-datepicker.embedded.bordered {
    padding-top: 12px;
    border: 1px solid #d7d7d7;
    border-radius: 0.1rem;
    .react-datepicker__navigation {
        top: 24px;
    }
}


/* Map overlays */

.map-overlay {
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-weight: bold;
    background-color: rgba($color: #000000, $alpha: .2);
    text-shadow: 0 0 5px #000000;
    z-index: 10;
}


/* Slider & Range */

.rc-slider-mark {
    font-size: 8px;
    @media (min-width: 576px) {
        font-size: 12px;
    }
}

.rc-slider-dot {
    bottom: 0;
    width: 6px;
    height: 6px;
    border-color: $theme-color-6;
    background-color: $theme-color-6;
    &.rc-slider-dot-active {
        background-color: white;
        border-color: $theme-color-6;
    }
}


/* ProfileImagePicker */

.profile-avatar {
    cursor: pointer;
    max-width: 110px;
    border: 1px solid $separator-color;
    border-radius: 100%;
    &.selected {
        border-color: $theme-color-1;
    }
    img {
        border-radius: 100%;
    }
}


/* Time Limit */

.time-limit-clock {
    font-size: 4rem;
    font-weight: bold;
    line-height: 1;
}

.time-limit-clock-small {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
}


/* AppsList */

.app-icon {
    img {
        display: block;
        max-width: 48px;
        max-height: 48px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}


/* Misc */

.react-datepicker__day--today {
    color: #6c757d!important;
    &.react-datepicker__day--selected {
        color: white!important;
    }
}

.react-datepicker__day--disabled {
    @extend .disabled-date;
    // cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.absolute-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.invalid-feedback {
    z-index: 1000;
}

.separator-tabs {
    font-size: 1rem;
}

.text-bigger {
    font-size: 1.25rem;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: var(--green);
}

.react-datepicker__triangle {
    display: none!important;
}

.container-fluid {
    margin-top: 0px;
}

main {
    margin-top: 95px;
}

#app-container .sidebar {
    padding-top: 70px;
}

@media screen and (min-width: 992px) {
    .map-item {
        height: 600px;
    }
}

// Main menu disabling features
.app-functions-main-menu {
    .nav-item {
        a.disabled {
            pointer-events: none;
            opacity: 0.35;
            background: #d7d7d7;
            color: $primary-color !important;
        }
    }
}

// Demo Warning Alert
.demo-warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close {
        height: 100%;
    }
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        text-align: center;
        .btn {
            margin-top: 1rem;
        }
    }
}

// Profile settings
.profile-device-selector {
    display: flex;
    flex-direction: column;
    .micro-btns {
        display: flex;
        margin-left: auto;
        .btn {
            font-size: 0.6rem;
            padding: 0.2rem;
            margin-left: 0.2rem;
            display: flex;
            align-items: center;
            line-height: 1;
            color: darken($separator-color, 20%);
            i {
                font-weight: bold;
            }
            &:first-child {
                margin-right: 0.2rem;
            }
        }
    }
}

.profile-selector {
    display: flex;
    flex-wrap: wrap;
    >div {
        display: flex;
        align-items: center;
        border: 1px solid $separator-color;
        border-bottom: 2px solid $separator-color;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        margin-bottom: -1px;
        opacity: 0.65;
        margin-right: 0.4rem;
        padding-right: 12px;
        ;
        overflow: hidden;
        padding: 0.2rem 0.35rem;
        z-index: 2;
        transition: all .3s ease;
        flex-shrink: 0;
        overflow: visible;
        img {
            width: 40px;
            margin-right: 5px;
            filter: grayscale(100%);
            opacity: 0.7;
        }
        h5 {
            margin: 0;
            font-size: 0.9rem;
        }
        &.active,
        &.mobile-selector {
            opacity: 1;
            background: #fff;
            border-bottom-color: $theme-color-1;
            img {
                opacity: 1;
                filter: none;
            }
            h5 {
                font-weight: bold;
            }
        }
        &:hover {
            opacity: 1;
            cursor: pointer;
            background: #fff;
        }
        &.add-new {
            opacity: 1;
            img {
                opacity: 1;
                width: 30px;
                filter: none;
            }
        }
        &.mobile-selector {
            display: none;
        }
    }
    @media (max-width: 800px) {
        flex-wrap: nowrap;
        >div {
            display: none;
            margin-right: 0;
            &.active {
                display: flex;
                flex: 1;
                border-top-right-radius: 0;
                border-right: none;
            }
            &.mobile-selector {
                display: flex;
                margin-right: 0.35rem;
                border-top-left-radius: 0;
                border-left: none;
                position: relative;
                padding: 0;
                width: 50px;
                .dropdown {
                    position: static;
                }
                .dropdown-toggle {
                    position: absolute;
                    top: 0;
                    border-radius: 0;
                    border-top-right-radius: 7px;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                }
            }
            &.add-new {
                display: flex;
                margin-left: auto;
            }
        }
    }
    @media (max-width: 359px) {
        >div {
            padding-left: 0.5rem;
            .profile-icon {
                display: none;
            }
        }
    }
}

.active-profile-card {
    border: 1px solid $separator-color;
    border-top: 2px solid $separator-color;
    margin-bottom: 1.5rem;
    .card-body {
        padding: 0;
    }
    .add-new-device-btn {
        padding: 2rem;
        text-align: center;
        .btn {
            width: 300px;
            max-width: 100%;
        }
    }
    .profile-devices {
        display: flex;
        flex-wrap: wrap;
    }
    .device-item {
        display: flex;
        padding: 0.7rem;
        align-items: center;
        border-right: 1px solid $separator-color;
        border-bottom: 1px solid $separator-color;
        background: #F5F5F5;
        transition: all .3s ease;
        margin-top: -1px;
        border-top: 1px solid $separator-color;
        .device-choose-button {
            display: flex;
            width: 30px;
            height: 30px;
            padding: 0;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            border: 1px solid $separator-color;
            margin-right: 10px;
            background: lighten($separator-color, 10%);
            i {
                font-size: 1rem;
                font-weight: bold;
                display: none;
                color: #fff;
            }
        }
        h5 {
            margin: 0;
            line-height: 1;
            margin-right: 0.7rem;
            i {
                font-size: 0.8rem;
            }
        }
        &.active {
            background: #FFF;
            h5 {
                font-weight: bolder;
            }
            .device-choose-button {
                background: var(--green);
                border-color: var(--green);
                i {
                    display: block;
                }
            }
        }
        &:hover {
            background: #fff;
        }
        @media (max-width: 1280px) {
            width: 25%;
            flex-grow: 0;
            flex-shrink: 0;
        }
        @media (max-width: 920px) {
            width: 33.333333%;
        }
        @media (max-width: 800px) {
            width: 50%;
        }
        @media (max-width: 550px) {
            width: 100%;
        }
    }
}

// Btn disabled
.btn.disabled,
.btn:disabled {
    opacity: .35;
}

// Header padding fix
// Purchase Page
.purchase-page-header {
    i {
        color: var(--green);
        font-size: 1.0rem;
    }
    .small-text {
        font-size: 1.0rem;
    }
    img {
        width: 60px;
    }
    .shield {
        max-width: 330px;
    }
    .shield-header {
        font-size: 1.7rem;
        @media (max-width: 576px) {
            font-size: 1.5rem;
        }
    }
}

.purchase-page {
    width: 94%;
    @media (max-width: 1000px) {
        width: 100%;
    }
    .benjamin-logo-container {
        padding-top: 0.5rem;
    }
    .plan {
        border: 2px solid #cacaca;
    }
    .optimal-plan {
        border: 2px solid var(--green);
        .optimal-plan-label {
            font-size: 1rem;
            background: var(--green);
            color: var(--white);
        }
    }
    .title {
        margin-bottom: 2rem;
        h1 {
            text-align: center;
            padding: 0;
            margin-bottom: 0.5rem;
            font-size: 2.3rem;
            padding-top: 14px;
        }
        i {
            color: var(--green);
            font-size: 1.3rem;
        }
    }
}

.feature-item {
    i {
        color: var(--green);
        font-size: 2rem;
    }
    .title {
        color: #2396F3;
        font-weight: 600;
    }
    .desc {
        font-size: 1rem;
    }
}

.glide__arrows {
    text-align: center;
    margin: 2rem 0;
    .glide__arrow {
        display: none;
    }
    .slider-dot {
        width: .8rem;
        height: .8rem;
        &.glide__bullet--active {
            background-color: var(--orange)
        }
    }
}

.glide__slides {
    .desc {
        white-space: normal;
        font-size: 1rem;
    }
    .glide__item {
        border-radius: 10px;
    }
}

// raect rater
$react-rater-link: rgb(209,
212,
16) !default;
$react-rater-hover: rgb(175,
159,
15) !default;
$react-rater-active: rgb(206,
188,
29) !default;
.react-rater {
    display: inline-block;
    line-height: normal;
    >* {
        display: inline-block;
    }
}

.react-rater-star {
    cursor: pointer;
    color: $react-rater-link;
    position: relative;
    &.will-be-active {
        color: $react-rater-hover;
    }
    &.is-active {
        color: $react-rater-active;
        filter: none;
    }
    &.is-active-half:before {
        color: $react-rater-active;
        content: '\2605';
        position: absolute;
        left: 0;
        width: 50%;
        overflow: hidden;
    }
    &.is-disabled {
        cursor: default;
        color: #dcdcdc;
        filter: grayscale(1);
    }
    &.is-disabled.is-active {
        color: $react-rater-link;
        filter: none;
    }
}

@mixin price-monthly {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 1rem;
    color: var(--green);
    background-color: #E7F1E2;
}

//modal
.plan-2y-box {
    .cancel-link {
        color: var(--blue);
        text-decoration: underline;
        font-size: 1.2rem;
    }
    .price-monthly {
        @include price-monthly();
        font-size: 1.2rem;
        margin-bottom: 0;
    }
    .modal-box-button {
        background-color: #40A846;
        border-radius: 0;
        width: 100%;
        border: 0;
        &:active {
            background-color: #40A846 !important;
        }
        .ico-forward {
            height: 30px;
            width: 21px;
            display: inline-block;
            top: 8px;
            position: relative;
            margin-left: 0.7rem;
            background-image: url("../../media/dalej.png");
        }
    }
    .plan-2y-box-modal-header {
        color: var(--blue);
        font-size: 1.7rem;
    }
    .plan-2y-box-modal-subheader {
        font-size: 1.7rem;
        line-height: 2.5rem;
        color: #6E6E6E;
        .discounted-price {
            font-size: 2.2rem;
            font-weight: bold;
        }
    }
    .discount-ico {
        background-image: url("../../media/plan-2y-box-discount-ico.svg");
        width: 171px;
        height: 130px;
        margin-top: -90px;
        div {
            color: white;
            font-weight: bold;
            line-height: 8.4rem;
            display: block;
            font-size: 2.5rem;
        }
        .single-percent {
            font-size: 4.2rem;
        }
    }
}

.plan-details {
    .plan-icon {
        width: 150px;
        height: 150px;
        background-size: contain;
        background-position: 0 0;
        background-repeat: no-repeat;
        margin-top: -1rem;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        .badge {
            position: absolute;
            text-transform: uppercase;
            width: 100px;
            text-align: center;
            bottom: 20px;
            left: 25px;
        }
    }
    .card-body {
        display: flex;
        flex-direction: column;
    }
    .card-title {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0.5rem;
    }
    .price {
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        color: $theme-color-green;
    }
    .old-price {
        font-size: 1.3rem;
        color: var(--gray);
        text-decoration: line-through;
        margin-right: 0;
        text-align: center;
        margin-bottom: -10px;
        margin-top: 10px;
    }
    .price-monthly {
        @include price-monthly();
    }
    .max-devices-count {
        font-size: 0.9rem;
        >div {
            font-size: 0.8rem;
        }
    }
    &:nth-child(1) .plan-icon {
        background-image: url("../../media/plan-icon1.png");
        ;
    }
    &:nth-child(2) .plan-icon {
        background-image: url("../../media/plan-icon2.png");
        ;
    }
    &:nth-child(3) .plan-icon {
        background-image: url("../../media/plan-icon3.png");
        ;
    }
    .features-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1.2rem;
        >div {
            display: flex;
            align-items: center;
            font-size: 0.95rem;
            margin-bottom: 0.4rem;
            i {
                margin-right: 0.5rem;
                color: $success-color;
                font-weight: bold;
            }
            &:first-child {
                font-size: 1.3rem;
                padding: 0.4rem 0;
                margin-bottom: 0.7rem;
                border-bottom: 1px solid $separator-color;
                width: 100%;
                border-top: 1px solid $separator-color;
            }
        }
    }
    .choose-plan-btn-bottom {
        margin-top: auto;
    }
    .choose-plan-btn-top {
        width: 70%;
        margin: 0.5rem auto 0.5rem;
        font-size: 1rem;
    }
}

.credits-info {
    font-size: smaller;
    font-style: italic;
    margin: 0 10px;
}

.purchase-plan-skip {
    text-align: center;
    
    margin: 0 auto 1rem;
    font-size: 1rem;
}

.purchase-plan-skip2 {
    @media (min-width: 768px) {
        display: none;
    }
}

// Custom Form Checkbox
.custom-form-check {
    display: flex;
    label {
        margin-bottom: 0;
        padding-top: 0.14rem;
        a {
            color: $theme-color-1;
        }
    }
}

// Settings
.settings-list {
    >div {
        margin-bottom: 0.6rem;
    }
}

// Aplications list checkbox
.block-new-apps-checkbox {
    padding: 1rem 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid $separator-color;
    border-top: 1px solid $separator-color;
    font-size: 1rem;
}

.block-new-apps-filter {
    margin-bottom: 1rem;
    border-bottom: 1px solid $separator-color;
    font-size: 1rem;
}

.save-new-locations-checkbox {
    padding: 1rem 0;
    margin-top: 1rem;
    border-top: 1px solid $separator-color;
    font-size: 1rem;
}

// Account menu
.account-menu {
    .dropdown-menu {
        padding-top: 0;
    }
    .account-menu-label {
        text-transform: uppercase;
        @media (max-width: 400px) {
            font-size: 0.65rem;
        }
    }
    .account-email {
        padding: 0.5rem 1rem 0.5rem 1.5rem;
        font-weight: bold;
        background: lighten($separator-color, 10%);
        border-bottom: 1px solid $separator-color;
    }
}

// Purchase status page
.purchase-status-page {
    .return-button {
        text-align: center;
    }
    .alert {
        text-align: center;
        font-size: 1.2rem;
        min-height: 100px;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: none;
    }
}

// Date picker with buttons
.datepicker-with-buttons {
    .btn:first-child {
        margin-right: 0.5em;
    }
    .btn:last-child {
        margin-left: 0.5em;
    }
}

.date-range-picker {
    //display: flex;
    align-items: center;
    text-align: right;
    margin-bottom: 10px;
    .btn-group {
        margin-right: 0.5em;
    }
}

// Add Device Page
.add-device-page-container {
    margin: auto;
    width: 100%;
    max-width: 800px;
}

.add-device-type-select {
    margin: 2rem 0;
    justify-content: space-between;
    display: flex;
    >a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        flex-basis: 0;
        width: 100%;
        border: 2px solid $separator-color;
        flex-grow: 1;
        flex-basis: 0;
        margin: 0 0.5rem;
        border-radius: 1rem;
        transition: all .3s ease;
        box-sizing: border-box;
        .img {
            width: 100%;
            height: 150px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 1rem 0;
        }
        .btn {
            margin-top: auto;
        }
        &.device-desktop .img {
            background-image: url("../../media/computer.png");
        }
        &.device-mobile .img {
            background-image: url("../../media/mobile.png");
        }
        &:hover {
            border-color: $theme-color-1;
        }
    }
    @media (max-width: 580px) {
        display: inline-block;
        >a {
            width: auto;
        }
        >a:first-child {
            margin-bottom: 1rem;
        }
    }
}


.add-device-body {

    @media (max-width: 580px) {
    
        padding: 0rem !important;
    }
}


.plan-info-row {
    display: block;
    margin-bottom: 10px;
}


.plan-info-body
{
    @media (max-width: 580px) {
    
        padding: 0rem !important;
    }

}

.plan-info-card
{
    @media (max-width: 580px) {
        //margin: 0px !important;
        padding: 1rem !important;
    }

}

.plan-info-card-body
{
    padding: 1.00rem !important;

    @media (max-width: 580px) {        
        padding: 0.0rem !important;
    }

}

.plan-info-title
{
    font-size: 1.1rem;
    font-weight: bolder;
    margin-bottom: 20px;
}

.plan-info-msg1
{
    font-size: 0.9rem;
    font-weight: bolder;
    
    margin-right: 10px;
    display: inline;
}

.plan-info-msg2
{
    font-size: 0.9rem;
    font-weight: normal;
    
    display: inline;
}

.plan-payment-row {
    font-weight: normal;
    font-size: 0.8rem;
}

.plan-payment-row-header {
    font-weight: bolder;
    font-size: 0.8rem;
}

.plan-info-close-button
{
    text-align: center;
    margin: 20px;
}

.dark-spinner
{
    background-color: #2396F3 !important;
}

.link-multiple-state
{
    color: $theme-color-blue;
    &:hover {
        opacity: 0.8;
    }
    text-decoration: underline;
    cursor: pointer;
}

.plan-info-clickable-link
{
    margin-left: 10px;
}

.hide-scroll {
    overflow: hidden;
}

.auto-scroll {
    overflow: auto;
}

.badge-radius {
    border-radius: .25rem;
}

.direction-in {
    color: rgb(102, 167, 11)
}

.direction-out {
    color: rgb(35, 82, 211)
}

.show-pass-ico {
    cursor: pointer;
    position: absolute;
    top: 25%;
    margin-right: 10px;
    right: 0;
    // background: rgb(255, 255, 255);
    padding: 2px 2px;
    border-radius: 4px;
    // font-weight: 700;
    // font-size: .8em;
    >img {
        width: 25px;
        height: auto;
        opacity: 0.8;
    }
}

.topAppsTable {
    >tbody {
        >tr {
            >td {
                padding: 3px;
            }
        }
    }
}

.topAppsTableIcon img {
    display: block;
    width: 48px;
    height: 48px;
    margin-right: 10px;
}

.topAppsTableText {
    padding-right: 15px;
    font-size: 0.8rem;
    display: block;
    //white-space: nowrap;
}

.topAppsTableName {
    font-size: 1.0rem;
    font-weight: bold;
}

.topAppsTableProgress {
    width: 100%;
    height: 25px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
    &:hover {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.10);
    }
    transition: box-shadow 1s;
}

.topAppsTableProgressBar {
    // background-color: yellow !important;
    // background-color: yellow ;
    opacity: 0.90;
    &:hover {
        opacity: 1;
    }
    transition: opacity 0.5s height 0.5s;
}

.yt-text-num {
    display: inline-block;
    vertical-align: middle;
}

.yt-main {
    margin-bottom: 15px;
}

.yt-thumb {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
}

.yt-info {
    display: inline-block;
    vertical-align: middle;
}

.yt-text-title {
    font-size: 1.0rem;
    font-weight: bold;
}

.yt-text-author {
    font-size: 0.9rem;
}

.yt-text-date {
    font-size: 0.9rem;
}

.yt-blocked {
    color: #dc3545 !important;
}

.rodo {
    &-box {
        @extend .rodo;
        position: fixed;
        width: 100%;
        bottom: 5px;
        left: 0px;
    }
    &-inline {
        @extend .rodo;
        margin-top: 10px;
        width: 100%;
        margin-bottom: 10px;
    }
    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        >div {
            margin: 0;
            z-index: 99999;
            background: white;
            padding: 20px;
            border-radius: 5px;
            border: 1px rgb(220, 220, 220) solid;
            >a {
                color: orange;
                font-weight: bolder;
            }
        }
    }
}

.rodo-x {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 10px;
    float: right;
}

.gps-warning {
    background: #fff89d;
    width: 100%;
    padding: 2px;
}

.app-list-name {
    line-break: anywhere;
}

.div-as-a {
    cursor: pointer;
}

.sidebar-icon {
    margin-bottom: 0px;
}

.sidebar-yt-icon {
    width: 40px;
    margin-bottom: 7px;
}

.sidebar-screen-icon {
    width: 40px;
    margin-bottom: 7px;
}

.disabled-date {
    opacity: 0.3;
}

.modal-large-title {
    font-size: large;
    font-weight: bolder;
}

.modal-centered {
    //position: absolute;
    top: 30%;
    //left: 50%;
    margin-top: -50px;
    //margin-left: -50px;
}

.set-primary-color {
    color: $theme-color-1;
    font-weight: bold;
}

.url-ico-container {
    width: 35px;
    height: 35px;
}

.search-ico-container {
    width: 18px;
    height: 18px;
}

.cart-purchase-expired-alert {
    font-size: 16px;
}

.screen-list-image .selected {
    box-shadow: #dc3545 0 0px 0px 6px;
}

.screen-list-image .say-no {
    animation: say-no-frames 0.7s;
    animation-iteration-count: 1;
}

@keyframes say-no-frames {
    0% {
        box-shadow: #ffffff 0 0px 0px 6px;
    }
    15% {
        box-shadow: #dc3545 0 0px 0px 6px;
    }
    30% {
        box-shadow: #ffffff 0 0px 0px 6px;
    }
    45% {
        box-shadow: #dc3545 0 0px 0px 6px;
    }
    60% {
        box-shadow: #ffffff 0 0px 0px 6px;
    }
    75% {
        box-shadow: #dc3545 0 0px 0px 6px;
    }
    100% {
        box-shadow: #ffffff 0 0px 0px 6px;
    }
}

.screen-list-image {
    align-items: center;
    justify-content: center;
    display: flex;
}

.screen-list-card-header {
    position: sticky;
    top: 70px;
    background: white;
    z-index: 3;
    padding-top: 20px;
    padding-bottom: 20px;
}

.verification-code {
    &__error-msg {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__inputs {
        input {
            border: 1px solid #999;
            border-radius: 4px;
            font-size: 16px;
            text-align: center;
        }
        input:focus {
            border: 1px solid #FF6905 !important;
            outline-color: #FF6905;
        }
    }
    &__text {
        color: red;
        font-size: 16px;
        font-weight: bold;
    }
}

.panel-page .email-confirmation-content {
    @media (max-width: 800px) {
        position: relative !important;
    }
}

@media (max-width: 768px) {
.h-mback {
    margin-top:20px;
}
.h-mback-border-bottom {
    border-top: 1px solid #f3f3f3;
    padding-top: 20px;
    margin-bottom: 10px;
}
  }


.update-password-warning-box {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
}

.screen-image {
    text-align: center;
    .portrait {
        max-width: 40%;
        @media (max-width: 419px) {
            max-width: 60%;
        }
    }
}

.url-item {
    .badge {
        font-size: 12px;
    }
    .copy-link-btn {
        cursor: pointer;
        background-color: transparent;
    }
    .copy-link-wrapper {
        justify-content: flex-end;
    }
    .copied-link-wrapper {
        align-items: center;
        display: flex;
    }
}

.google-maps-wrapper {
    width: 100%;
    height: 600px;
    position: relative;
}

.gm-ui-hover-effect {
    visibility: hidden;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.embeded-player {
    width: 100%;
    height: 450px;
    transition: 0.3s;
}

.embeded-player-collapsed {
    height: 0px !important;
}

.embeded-player-add-device {
    margin-top: 20px;
    margin-bottom: 20px;
}

.rounded-border {
    display: inline;
    margin: 5px;
    padding: 5px;
    width: 30px !important;
    height: 30px !important;
    border: solid;
    border-width: 1px;
    border-color: #555;
    border-radius: 50%;
    i {
        font-weight: bold;
    }
}

.login-logo {
    width: 194px;
    height: 44px;
}

.form-group .react-tel-input .form-control {
    width: 100%;
    height: inherit;
}

.bonus-time-text {
    text-align: center;
    font-size: large;
    //width: 150px;

    @media (min-width: 380px) {
        width: 150px;
    }
}

.button-bonus {
    font-size: medium;
}

.rounded-button {
    margin: 5px;
    padding: 5px;
    width: 45px!important;
    height: 45px!important;
    border-style: solid;
    border: 1px solid #d7d7d7;
    font-size: medium;
}

.rounded-button-today {
    font-weight: bold;
    border: 1px solid #57ce3a;
}

.rounded-button-time
{
    font-size: 0.7rem;
    font-weight: 500;
    white-space: nowrap
}

.rounded-button-item
{
    display: flex; 
    justify-content: center;
}

.time-limit-oneline
{
    white-space:nowrap;
}

.purchase-checkout
{
    margin: auto!important;    
}

.purchase-checkout-card
{
    padding: 0.8rem;
    border-radius: 0.4rem;
    background: rgb(212, 211, 200);
}

.purchase-checkout-buttons
{
    text-align: center;
    align-items: center;
    padding: 0.1rem;
}

.purchase-checkout-button
{
    margin: 0.5rem;
    width: 110px;
}

.btn-sm-profile{
    padding: 1.2rem 0.9rem;
    font-size: 20px;
}

.purchase-checkout-error
{
    display: block;
    width: 100%;
 
    span
    {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        text-align: center;
        display: table;
        margin: 0 auto;
        width: 250px;
        min-height: 30px;

        color: #dc3545;
        word-wrap: break-word;
        font-weight: 700;
    }
}

.purchase-checkout-subscribe-description
{
    display: block;
    font-size: x-large;
    margin-bottom: 20px;
    line-height: 25px;
    font-size: 22px;
}

.purchase-checkout-subscribe-devices
{
    display: block;
    font-size: large;
    margin-bottom: 5px;    
}

.purchase-checkout-subscribe-price
{
    display: block;
    font-size: large;    
}

.purchase-checkout-subscribe-currency
{
    display: block;
    font-size: x-small;
    margin-bottom: 20px;
}

.purchase-checkout-shield
{
    float: right;
    width: 50px;
}

.purchase-checkout-stripe
{
    margin-top: 30px;
    width: 200px;
}

.upgrade-plan-body 
{
    @media (max-width: 991px) {
        //margin: 0px !important;
        padding: 0rem !important;
    }
    @media (max-width: 580px) {
        //margin: 0px !important;
        padding: 1rem !important;
    }
}

.upgrade-plan-card 
{
    @media (max-width: 991px) {
        //margin: 0px !important;
        padding: 0.5rem !important;
    }
    @media (max-width: 580px) {
        //margin: 0px !important;
        padding: 1rem !important;
    }

}

.upgrade-plan-card-body 
{
    @media (max-width: 991px) {
        //margin: 0px !important;
        padding: 0rem !important;
    }
    @media (max-width: 580px) {
        //margin: 0px !important;
        padding: 1rem !important;
    }
}

.upgrade-plan-select 
{
    margin: 2rem 0;
    justify-content: space-between;
    display: flex;

    >a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        flex-basis: 0;
        width: 100%;
        border: 2px solid $separator-color;
        flex-grow: 1;
        flex-basis: 0;
        margin: 0 0.5rem;
        border-radius: 1rem;
        transition: all .3s ease;
        box-sizing: border-box;

        .btn {
            margin-top: auto;
        }
        &:hover {
            border-color: $theme-color-1;
        }
    }
    @media (max-width: 580px) {
        display: inline-block;
        >a {
            width: auto;
        }
        >a:first-child {
            margin-bottom: 1rem;
        }
    }
}

.mini-currency
{
    margin-left: 10px;
    font-size: x-small;
}

.checkout-body
{
    @media (max-width: 580px) {
        //margin: 0px !important;
        padding: 0rem !important;
    }
}

.checkout-card
{
    @media (max-width: 580px) {
        //margin: 0px !important;
        padding: 0.6rem !important;
    }
}

.checkout-card-body
{
    @media (max-width: 580px) {
        
        padding: 0.0rem !important;
    }
}

.button-x
{
    border-radius: 3rem;
    margin: 10px;
    position: absolute;
    top: 0px;
    right: 0px;
}



.shrinkable::before, .shrinkable::after {
    display: inline-block;
    //width: 100%;
    max-width: 50%;
    overflow: hidden;
    white-space: pre;
    line-break: anywhere;
}

.shrinkable::before {
    content: attr(data-content-start);
    text-overflow: ellipsis;
    white-space: pre;
    line-break: anywhere;
}

.shrinkable::after {
    content: attr(data-content-end);
    text-overflow: clip;
    line-break: anywhere;
    white-space: pre;
    direction: rtl;
}

.line-break-any {
    line-break: anywhere;   
}

.white-space-nowrap {
    white-space: nowrap;
}

.QRCode {
    width: 200px;
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}


.bottom-modal {
    position: fixed;
    bottom: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    left: 0;
  }
  
  .bottom-modal-body {
    max-width: 600px;
    height: auto;
    margin: 0 auto;
    position: relative;
  }
  
  .bottom-modal-body p {
    text-align: left;
  }
  
  .bottom-modal-center {
    text-align:center !important;
  }
  
  .bottom-modal-lf {
    font-weight: 800;
  }
  
  .bottom-modal-btn {
    width: 100%;
  }
  
  .gdpr-txt-header {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }
  
  .purchase-tile-title {
    height: 1.5rem;
  }

  .purchase-tile-no-devices {
    visibility: hidden;
  }