@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    background-color: #f9fafb;
    color: #111827;
    line-height: 1.5;
}

.answ {
    color: #111827;
    line-height: 1.5;
    font-size: 18px !important;
    
}

@media (min-width: 768px) {
    .answ {
        min-height: 600px;  // to jest po to, aby ekran nie skalal podczas wybierania kolejnych pozycji z historii
    }
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
}

.edu-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 10px;
    margin-bottom: 24px;
}

@media (max-width: 550px) {

    .container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .edu-card {
        border-radius: 0px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    }
}

.congratulations {
    display: flex;
    justify-content: space-between;
}

.congratulations-content h2 {
    margin-bottom: 8px;
}

.congratulations-content p {
    color: #6b7280;
    margin-bottom: 16px;
}

.avatar {
    position: relative;
}

.avatar::after {
    content: '⚡';
    position: absolute;
    right: -5px;
    bottom: -5px;
    background-color: #e7523e;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.edu-button {
    background-color: #e7523e;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.1s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0 1px 2px 1px #e7523e !important;
}

.edu-button-container {
    display: flex;
    justify-content: left;
    gap: 20px;
}

.buttonmore {
    background-color: #26c6f9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.1s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 300px;
}
  
.buttondesc {
    background-color: #72e128;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.1s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 300px;
}

.edu-button:hover, .buttonmore:hover, .buttondesc:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.edu-button:active, .buttonmore:active, .buttondesc:active {
    transform: translateY(1px);
}

.stats {
    display: flex;
    gap: 24px;
}

.leftedu {
    text-align: left !important;
}

.stat-card {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.stat-card h3 {
    font-size: 24px;
    margin-bottom: 4px;
}

.stat-card p {
    color: #6b7280;
    font-size: 14px;
}

.chart-placeholder {
    height: 60px;
    background: linear-gradient(to top right, #e0e7ff 0%, #c7d2fe 100%);
    border-radius: 8px;
    margin-top: 12px;
}

.subjects-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
}

.tabs-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;
    box-sizing: border-box;
    height: 100px;
}

.button-school-class {
    width: 150px;
}


.tabedu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    cursor: pointer;
    /*transition: all 0.3s ease;*/
    width: calc(16% - 4px);
    min-width: 120px;
    position: relative;
    flex-shrink: 0;
    margin-right: 13px;
    box-sizing: border-box;
    white-space: nowrap;
}

.hredu
{
    border: 0.5px solid #e5e7eb;
    margin-bottom: 20px;
    width: 100%;
}

.tabedu:hover {
    border: 1px solid #e7523e;
}

.tabedu.active {
    background-color: #f7f4f4;
    border: 1px solid #e7523e;
}

.tab-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
}

.tab-title {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #374151;
}

.tab-indicator {
    width: 8px;
    height: 8px;
    background-color: #e7523e;
    border-radius: 50%;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
}

.tabedu.active .tab-indicator {
    display: block;
}

.actions-box {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 22px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 116px;
}

.actions-header {
    font-size: 16px;
    font-weight: 600;
    color: #374151;
    margin-bottom: 12px;
}

.actions-buttons {
    display: flex;
    gap: 12px;
}

.edu-dropdown {
    position: relative;
    width: 160px;
}
.container-last-questions {
    width: 300px; /* Or any specific width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.container-last-questions-thumb-img
{   
    max-height: 3.5rem;
    cursor: pointer;
}


.buttonaddcredits {
    background-color: #26c6f9;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    transition: background-color 0.3s, transform 0.1s;
    display: inline-flex;
    /* align-items: center; */
    justify-content: center;
}
.edu-dropdown-toggle {
    background-color: #EEF2FF;
    color: #e7523e;
    border: 1px solid #E0E7FF;
    border-radius: 25px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.edu-dropdown-toggle:after {
    content: '▼';
    margin-left: 8px;
    font-size: 10px;
    transition: transform 0.3s ease;
}

.edu-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #E0E7FF;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    z-index: 1000;
    margin-top: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.edu-dropdown:hover .edu-dropdown-menu,
.edu-dropdown:focus-within .edu-dropdown-menu {
    opacity: 1;
    visibility: visible;
}

.edu-dropdown:hover .edu-dropdown-toggle:after,
.edu-dropdown:focus-within .edu-dropdown-toggle:after {
    transform: rotate(180deg);
}

.edu-dropdown-item {
    padding: 10px 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: block;
    color: #374151;
    text-decoration: none;
}

.edu-dropdown-item:hover {
    background-color: #F3F4F6;
}

.form-list-section {
    display: flex;
    gap: 24px;
}

.form-container {
    flex: 2;
}

.list-container {
    flex: 1;
    scroll-behavior: auto;
    max-height: 32em;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
}

.form-group {
    margin-bottom: 16px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    //font-weight: 500;
    color: #374151;
}

.edu-input-question {
    min-height: 240px;
}

.edu-input-image {
    min-height: 240px;
    margin-bottom: 10px;
    cursor: pointer;
}

.edu-input-image-question {
    min-height: 100px;
    font-size: 1.0rem;
}


.list-item {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #e5e7eb;
}

.list-item:last-child {
    border-bottom: none;
}

.list-item-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 12px;
    padding-right: 12px;
    background-Color: #26c6f9;
}

.task-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 16px 0;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.edu-logo {
    width: 150px;
    height: auto;
}

.edu-description {
    text-align: right;
    color: #949494;
}

.edu-separator {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.task-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #26c6f9;
    border: none;
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
    width: 100%;
}

.task-button svg {
    margin-right: 8px;
}

.mobile-only {
    display: none;
}

.desktop-only {
    display: flex;
}

.desktop-only-block {
    display: bock;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.edu-dropdown:hover .edu-dropdown-menu,
.edu-dropdown:focus-within .edu-dropdown-menu {
    animation: fadeIn 0.3s ease;
}

.edu-button:focus,
.buttonmore:focus,
.buttondesc:focus,
.edu-dropdown-toggle:focus,
.edu-dropdown-item:focus {
    outline: 2px solid #e7523e;
    outline-offset: 2px;
}

@media (max-width: 768px) {
    .mobile-only {
        display: flex;
    }

    .desktop-only {
        display: none;
    }

    .desktop-only-block {
        display: none;
    }

    .stats, .form-list-section, .subjects-container {
        flex-direction: column;
    }

    .tabedu {
        width: calc(33.333% - 11px);
    }

    .actions-box {
        margin-left: 0;
        margin-top: 24px;
        width: 100%;
    }

    .description {
        font-size: 10px;
    }

    .edu-button-container {
        flex-direction: column;
        align-items: stretch;
    }

    .edu-button, .buttonmore, .buttondesc {
        width: 100%;
        margin-bottom: 10px;
    }

    .congratulations {
        flex-direction: column;
        align-items: center;
    }

    .avatar {
        margin-bottom: 20px;
    }
    .edu-dropdown {
        position: relative;
    }
    .mobile-only {
        display: block;
      }
}

@media (max-width: 480px) {
    .tab {
        width: calc(25% - 8px);
    }

    .buttonmore, .buttondesc {
        margin-bottom: 20px;
        width: 100%;
    }
}

@media (min-width: 1024px) {
    .form-list-section {
        // max-height: 350px;
    }
    .desktop-only {
        display: flex;
    }

    .desktop-only-block {
        display: bock;
    }
  }

  /* purchase plan buttons*/
  .device-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.device-button {
    padding: 7px 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: white;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.device-button:hover {
    background-color: #f0f0f0;
}
.device-button.device-active {
    border-color: #28a745;
    color: #28a745;
}
.badgenew {
    margin-left: -20px;
    margin-top: -6px;
    font-weight: 200;
  }
.eduimg {
    width: 800px;
}
.eduimgmobile {
    width: 305px;
}
.greencolor {
    color: #28a745;
}
.eduline-spacing {
    margin-top: 30px;;
}
.edutxt {
    font-size: 15px;;
}
.center {
    text-align: center;
}
.plan-details-height {
    height: 200px;
}
.purchase-more-details { 
    margin-top: -15px;
    text-align: right; 
    margin-right:10px;
    color: #949494;
}
.plan-tile-content {
    height: 140px;
}
.h55 {
    height: 55px;
}
.btn-sm {
    padding: .45rem 0.7rem;
    font-size: .8rem;
    line-height: 1.5;
}
@media (min-width: 768px) {
    .edu-activate-right {
        float: right
    }
}

.edu-cropper-img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    max-height: 15rem;
}

.cropper {
    height: 100%;
    max-height: 100%;
    width: auto;

    // nie dziala...
    // background-color: #FFF;
    // background: white;
    // color: #26c6f9;
}

.edu-cropper {
    // nie dziala...
    // background-color: #FFF;
    // background: white;
    // color: #26c6f9;
}

.cropper-bg {
    // nie dziala...
    // background-color: #949494;
    // background: white;
    // color: #26c6f9;
}

// srednio dziala bo biale jest szare...
.advanced-cropper {
    background:  white !important;
}

// dziala jak advanced-cropper...
// .advanced-cropper__background-wrapper {
//     background:  white !important;
// }

// nie dziala...
// .advanced-cropper-simple-handler {
//     background:  white !important;
// }

.modal-fullscreen {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
    z-index: 100000;
}

@media (min-width: 768px) {
    .modal-fullscreen {
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
    }
}

.modal-fullscreen-body
{
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    background: #FFF;
}

.modal-fullscreen-header {
    background-color: #FFF;
    text-align: center;
    padding: 5px;
}

.modal-fullscreen-main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FFF;
    overflow: auto;
    max-height: 100%;
}

.modal-fullscreen-footer {
    background-color: #FFF;
    color: white;
    padding: 10px;
    text-align: center;
}

.modal-fullscreen-footer button {
    margin-top: 0px;
}

@media (min-width: 768px) {
    .modal-fullscreen-footer button {
        width: 50%;
    }
}


.share-app {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.edu-description-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.share-link {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 20px;
    color: white !important;
    text-decoration: none;
    transition: opacity 0.3s;
}

.edu-url-txt
{
    margin-top: 1rem;
    margin-left: 2rem;
    margin-bottom: 0 !important;
}

@media (min-width: 1024px) {
    .edu-url-txt
    {
        margin-left: 3rem;
    }
}


.show-charts-button {
    transition: all 1s;
}

.show-charts-container {
    transition: height 1s;
    //overflow-y: hidden;
}

.show-charts-container-on {
    // height: 40px;
}

.show-charts-container-off {
    height: 0px;
    display: none;
}


.edu-link-style {
    color: #3B82F6;
    cursor: pointer;
    &:hover {
        color: #FF6905;
    }
}

.edu-link-style2 {    
    cursor: pointer;
    &:hover {
        color: #FF6905;
    }
}

.edu-link-privacy {
    color: #949494
}
